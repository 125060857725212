/**
 * This should be used when the application starts and, as such, should only
 * come with the minimal amount of dependencies.
 */
export const FullScreenLoading: React.FC<{}> = () => {
  return (
    <div className="full-screen-loading">
      <div>
        <div className="animation"></div>
      </div>
    </div>
  );
};
