import React from "react";
import { FullScreenLoading } from "./FullScreenLoading";
// import "./index.css";

const AppOuter = React.lazy(
  () => import(/* webpackPreload: true */ "./AppOuter")
);

export default function App() {
  return (
    <React.Suspense fallback={<FullScreenLoading />}>
      <AppOuter />
    </React.Suspense>
  );
}
